$gray-0: #fafafa;
$gray-1: #f9f9f9;
$gray-1-mid: #f3f3f3;
$gray-2: #eee;
$gray-3: #ddd;
$gray-4: #ccc;
$gray-5: #bbb;
$gray-6: #878787;
$gray-7: #767676;
$gray-8: #515151;
$gray-9: #313131;

$white: #fff;
$red: #ac4142;
$orange: #d28445;
$yellow: #f4bf75;
$yellow-1: #ffc640;
$green: #90a959;
$cyan: #75b5aa;
$blue: #268bd2;
$brown: #8f5536;
$darker-brown: #715944;

// scrollbars
$scrollbar-color: #715944a3;

//https://www.client9.com/css-system-font-stack-sans-serif-v3
$root-font-family: "IBM Plex Sans", system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Droid Sans", "Ubuntu", "Helvetica Neue", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";

// poole's variables
$root-font-size: 17px;
$root-font-weight: 400;
// golden ratio https://grtcalculator.com
// 18px @ 33 px, 20px @ 33 px ~ 1.667em
$root-line-height: 1.667em;

$body-color: $gray-8;
$body-bg: $white;

$border-color: #e5e5e5;

$large-breakpoint: 38em;
$large-font-size: 19px;
$font-scale-dot7: .7rem;
$font-scale-dot8: .8rem;
//

// hyde-hyde
$small-device-font-size: $root-font-size;
$large-device-font-size: $large-font-size;

// https://www.client9.com/css-system-font-stack-monospace-v2
$code-font-family: "SFMono-Regular", "SF-Mono", Menlo, Monaco, Consolas, "Liberation Mono", "Roboto Mono", "Ubuntu Mono", "Courier New", Courier, monospace;
$code-font-size: .9rem;
$code-fence-font-size: .8rem;
//$code-color: #bf616a;
//$code-background-color: #f9f2f4;
$code-line-height: 1.4;

// links
$link-color: $blue;
$link-hover-color: $body-color;

// section
$section__title-font-size: 2.15rem;

// post
$post__subtitle-font-size: 1.5rem;
$gradient-color-1: #ff2c2c;
$gradient-color-2: #7a5e91;

// post meta
$meta-font-size: $font-scale-dot8;
$meta-font-weight: 300;
$meta-color: $gray-6;

// post tags
$tag-background-color: $gray-2;
$tag-color: $darker-brown;
$tag-font-size: .667rem;

// list of posts
$item__date-color: #9a9a9a;
$item__date-font-size: 1rem;
$item__title-big-font-size: 1.785rem;

// heading
$heading-font-weight: 400;
$h1-font-size: 2.15rem;
$h1-line-height: 1.25;
$h2-font-size: 1.85rem;
$h3-font-size: 1.5rem;
$h4-font-size: 1.3rem;
$h5-font-size: 1rem;


// sidebar
$sidebar-color: $darker-brown;
$sidebar-link-color: $yellow-1;
$sidebar-width: 16rem;
$site__title-font-size: 2.5rem;
$copyright-font-size: $font-scale-dot7;

// content
$content-max-width: 60rem; // @ ~70 CPL
$content-margin-left: $sidebar-width + 2rem;
$content-margin-right: 2rem;

// navigation
$navigation-color: $darker-brown;

// portfolio
$project__title-font-size: $h2-font-size;
$project__subtitle-font-size-big: $h3-font-size;
$project__subtitle-font-size-small: $h4-font-size;
$project__subtitle-font-style: italic;
$project__subtitle-color: #778492;
$ribbon-color: #276582;
$ribbon-background-color: #479fc8;


// Asciidoc
$small-title: $darker-brown;
$title-color: $gray-9;
$title-link-hover-color: $gray-8;
$h1-border-color: #dddddf;
$h1-color: $gray-9;
$background-color: $body-bg;
$sidebar-bg-color: $gray-2;
$sidebar-border-color: $gray-4;
$paragraph-color: $body-color;
$table-td-color: $body-color;
$table-border-color: $gray-4;
$table-alternate-bg-color: $gray-0;
$table-header-footer-bg-color: $gray-2;
$admonition-default-border-color: $gray-5;
$admonition-cite-default-color: rgba(0, 0, 0, 0.6);
$admonition-icon-shadow-color: rgba(0, 0, 0, 0.5);
$admonition-icon-tip-color: #4b70b4;
$admonition-icon-tip-shadow-color: rgba(155, 155, 0, 0.8);
$admonition-icon-important-color: #bf0000;
$admonition-icon-important-bg-color: #ffc8c8;
$admonition-icon-important-border-color: #ff6767;
$admonition-icon-warning-color: #bf4c00;
$admonition-icon-warning-bg-color: #f9dcc8;
$admonition-icon-warning-border-color: #f3aa79;
$admonition-icon-caution-color: #bf7900;
$admonition-icon-caution-bg-color: #f7e5ce;
$admonition-icon-caution-border-color: #fab55e;
$conum-color: $body-bg;
$conum-bg-color: $body-color;
$hlist-colist-table-bg-color: $gray-0;
$pre-bg-color: rgb(254, 254, 254);
$pre-color: $body-color;
$pre-bg-color: $gray-0;
$not-pre-code-bg-color: $gray-1-mid;
$attribution-color: rgba(229, 224, 216, 0.6);
$exampleblock-border-color: rgb(72, 71, 63);
$exampleblock-shadow-color: rgb(45, 46, 46);
$exampleblock-bg-color: rgb(255, 254, 247);
$link-color: $link-color;
$hover-link-color: $body-color;
$blockquote-quote-shadow-color: rgba(0, 0, 0, 0.1);

$block-switch-border-color: $border-color;
$block-switch-not-selected-tab-color: $brown;
$block-switch-not-selected-tab-bg-color: $body-bg;
$block-switch-selected-tab-color: $darker-brown;
$block-switch-selected-tab-bg-color: $gray-2;
$block-switch-selected-tab-bg-gradient-color: $yellow;
